<template>
  <div class="logo">
    <h1 class="logo__headline">{{ $t('title') }}</h1>
    <p class="logo__subline">{{ $t('subline') }}</p>
  </div>
</template>

<script>
export default {
  name: 'Logo'
};
</script>
