<template>
  <div class="menu-footer">
    {{ $t('meta.footerNotice') }} <a href="https://kovah.de/">Kevin Woblick</a> | <a href="https://github.com/Kovah/Taboo">Github</a>
  </div>
</template>

<script>
export default {
  name: 'MenuFooter'
};
</script>
